<template>
  <!--
router.json 的路由
     {
    "path": "/navigationBar",
    "name": "导航栏管理",
    "menuKey": "navigationBar",
    "component": "navigationBar/index.vue",
    "accessWay": "builtin",
    "meta": {
      "title": "导航栏管理",
      "icon": "DatabaseFilled",
      "breadcrumb": true
    },
    "hidden": false,
    "alwaysShow": true
  },


   -->

  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1100, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #province="{ record }">
          <div>{{ record }}</div>
          <div></div>
        </template>
        <template #city="{ record }">
          <div>{{ record }}</div>
          <div></div>
        </template>

        <template #gender="{ record }">
          <div class="iss-user">
            <a-tooltip title="查看议程" placement="top">
              <a-button
                type="primary"
                size="middle"
                shape="circle"
                ghost
                @click="handleClickViewMember(record)"
              >
                <template #icon><EyeOutlined /></template>
              </a-button>
            </a-tooltip>
            <span style="padding-left: 5px">{{ record.number }}</span>
          </div>
        </template>
      </grid>
    </div>
  </div>

  <!--  <iss-edit-->
  <!--    v-model:visible="visible"-->
  <!--    v-model:confirmLoading="confirmLoading"-->
  <!--    :init-value="activeItem"-->
  <!--    @fnOk="handleFnOkByEdit"-->
  <!--  />-->
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import contactApi from '@/api/contact.js';
// import dictionaryApi from '@/api/dictionary.js';
// import issEdit from './components/edit';
import { EyeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    // issEdit,
    EyeOutlined,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    const handleClickViewMember = record => {
      console.log('record', record);
      store.commit('common/setViewAgenda', record);
      // router 跳转到查看议程
      let id = record.id;
      router.push(`/dashboard/${id}`);
    };

    return {
      handleClickViewMember,
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'agendaName', label: '嘉宾名字' },

        {
          key: 'gender',
          label: '选择语言',
          type: 'select',
          dataset: [
            { label: 'Chiness', value: '中文' },
            { label: 'English', value: '英文' },
          ],
        },
        {
          key: 'auditStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: 'unaudited', label: '已发布' },
            { value: 'approve', label: '未发布' },
          ],
        },
        {
          key: 'receiveConsult',
          label: '发布时间',
          type: 'select',
          dataset: [
            { value: '0', label: '不接受' },
            { value: '1', label: '接受' },
          ],
        },
      ],
      columns: [
        { dataIndex: 'mobileNum', title: '语言', width: 80, ellipsis: true },
        {
          dataIndex: 'fullName',
          title: '栏目',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'gender',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'gender' },
          ellipsis: true,
        },
        {
          dataIndex: 'company',
          title: '发布时间',
          width: 180,
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        // {
        //   type: 'add',
        //   permission: 'classify:add',
        //   fnClick: () => {
        //     state.visible = true;
        //     state.activeItem = {};
        //   },
        // },
        //
        // {
        //   type: 'isPublish',
        //   label: '发布',
        //   icon: 'ExportOutlined',
        //   permission: 'agenda:publish',
        //   fnClick: () => {
        // agendaApi
        //   .agendaPublish('agenda:publish', { campaignId })
        //   .then(res => {
        //     if (res > 0) {
        //       message.success(`发布成功${res}条`);
        //       gridRef.value.refreshGrid();
        //     } else {
        //       message.info('当前没有可发布的议程！');
        //     }
        //   });
        // },
        // },
      ],
      options: [
        {
          type: 'publish',
          icon: 'CheckCircleOutlined',
          permission: 'navigationBar:publish',
          label: '发布',
          fnClick: ({ id }) => {
            console.log('id', id);
          },
        },

        {
          type: 'line',
          icon: 'StopOutlined',
          permission: 'navigationBar:line',
          label: '下线',
          fnClick: ({ id }) => {
            console.log('id', id);
            // resourcesApi.delete('resources:delete', { ids: [id] }).then(() => {
            //   message.success('操作成功');
            //   gridRef.value.refreshGrid();
            // }),
          },
        },
      ],
      url: contactApi.pageUrl,
      gridHeight: document.body.clientHeight - 386,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: () => {},
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
